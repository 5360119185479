
/******************
       import
******************/
@import "compass";
@import "modularscale";
@import "singularitygs";
// @import "singularity-extras";
@import "breakpoint";
// @import "sass/variables/**/*.scss";
// @import "sass/abstractions/**/*.scss";
// @import "sass/base/**/*.scss";
// @import "sass/components/**/*.scss";

@import '_constants.scss';
// @import '_icon.scss';
//@import '_rolling.scss';
@import '_glyphicon.scss';

// Css reset
//@import "compass/reset";

// // Compass typography
// @include establish-baseline;

/************************
    köret
************************/
    #menu-button{
        @include breakpoint($nav-menu-show){
            display: none;
        }
    }
    .nagydiv{
        width: 100%;
        min-width: $page-min-width;
        padding:  5px 5px;
        box-sizing: border-box;
    }
    .fejlec{
        height: 82px;
        width: 100%;
        position: relative;
        z-index: 2;
        @include breakpoint(max-width 355px) {
            >a:first-child{
                img{
                    width: 80px;
                    margin-top: 9px;
                }
            }
        }

        .header-welcome{
            display: none;
        }
        .personal-menu{
            float: right;
            margin-top: 52px;
            margin-left: 5px;
            button{
                margin: 0;
            }
            @include breakpoint(max-width 575px){
                display: block;
            }
        }
    }
    .mobil-menu-holder{
        clear: both;
        -webkit-user-select: none;
        display: block;
        margin: -2px 0 0;
        overflow-y: hidden;
        padding: 0;
        position: relative;
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll;
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .navbar{
        position: relative;
        height: 44px;
        overflow: hidden;
        width: 100%;
        .slide-menu-fade{
            background: linear-gradient(linear,left top,right top,color-stop(0,rgba(222, 216, 200,0)),color-stop(100%,rgba(222, 216, 200,1)));
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ded8c8+0,ded8c8+38&0+0,1+38 */
            background: -moz-linear-gradient(left, rgba(222,216,200,0) 0%, rgba(222,216,200,1) 38%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(222,216,200,0) 0%,rgba(222,216,200,1) 38%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(222,216,200,0) 0%,rgba(222,216,200,1) 38%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ded8c8', endColorstr='#ded8c8',GradientType=1 ); /* IE6-9 */
            height: 100%;
            position: absolute;
            pointer-events: none;
            right: -2px;
            top: 0;
            width: 20px;
            z-index: 1;
        }
    }
    .menu-container{
        // display: none;
        padding-right: 14px;
    }
    .mobil-menu{
        width: 100%;
        clear: both;
        .menu, .menu:visited, .menu:link {
            text-transform: uppercase;
            color: #fff;
            float: none;
            margin-right: 10px;
        }

    }
    .page{
        width: 100%;
    }
    .footer-container{
        width: 100%;
    }
    .login-container{
        @include breakpoint(0 575px) {
            display: none;
            position: absolute;
            z-index: 4;
            top: 94px;
            background: #f5f5f5;
            width: 100%;
            padding: 10px 5px 10px;
            box-sizing: border-box;
            margin: 0 0 0;
        }
        @include breakpoint(576px) {
            display: block;
        }   
    }
    .mobile-min430px{
        min-width: 430px;
    }
    .rotate-warning{
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: red;
        text-align: center;
        @include breakpoint(440px){
            display: none;
        }
    }
    .left-container{
        margin-top: 10px;
    }

/************************
    aukciók
************************/
    .left-almenu{
        position: relative !important;
        left: unset !important;
        top: unset !important;
        display: block !important;
        border: none !important;
    }
    .more{
        background: none !important;
    }
    .table-responsive{
         @include breakpoint(max-width 580px) {
            *{
                box-sizing: border-box;
            }
            br {
                content: ' '
            }

            br:after {
                content: ' '
            }
            thead{
                display: none;
            }
            tr{
                display: block;
                overflow: hidden;
                td:nth-of-type(1){
                    display: block;
                    float: left;
                    width: 90px;
                }
                td:nth-of-type(2){
                    display: block;
                    float: left;
                    width: calc(100% - 90px);
                    > *{
                        margin-left: 10px;
                    }
                }
                td:nth-of-type(3),td:nth-of-type(4),td:nth-of-type(5){
                    display: block;
                    float: left;
                    &:before{
                        content: attr(data-th);
                        float: left;
                        text-align: left;
                        padding-right: 10px;
                    } 
                }
                td:nth-of-type(3){
                    width: 40%;
                    text-align: left;
                }
                td:nth-of-type(4){
                    width: 60%;
                    text-align: right;
                }
                td:nth-of-type(5){
                    text-align: right;
                    float: right;
                    font-weight: bold;
                }
                .termek-nev{
                    margin-top: 0;

                }
                .lista-kosarba-gomb{
                    width: 132px;
                }
                .empty-category{
                    width: 100% !important;
                }
            }
            &.webshop{
                td:nth-of-type(3){
                    width: 100%;
                    text-align: left;
                }
                td:nth-of-type(4){
                    text-align: left;
                }
                td:nth-of-type(6){
                    float: right;
                    padding: 5px 5px 10px;
                }
            }
        }
    }
/************************
	design elemek
************************/
    .btn,a.btn{
        white-space: nowrap;
        vertical-align: middle;
        cursor: pointer;
        -webkit-transition: .3s;
        transition: .3s;
        padding: 10px;
        display: inline-block;
        text-decoration: none;
        border: none;
        font-size: 14px;
        font-family: $default-font;
        font-weight: bold;
        text-align: center;
        background-color: $icon-color;
        color: $text-color-light;
        &:hover,&:focus{
            background-color: #2C3E50;
            color: $text-color-light;
        }
        &:active{
            transform: translate(1px,1px);
        }
    }
    .btn-green, a.btn-green{
        background-color: #a3bd35;
        &:hover,&:focus{
            background-color: #59671f;
        }
    }
    .btn-transparent, a.btn-transparent {
        background: transparent;
        border: solid 2px $icon-color;
        color: $icon-color;
        padding: 8px;
        &:hover{
            border-color: #2C3E50;
        }
    }
    .btn-inactive,a.btn-inactive{
        background-color: #EEEEEE;
        color: white;
        border: #EEEEEE;
        cursor: default;
        &:hover,&:focus{
             background-color: #EEEEEE;
             color: white;
             border: #EEEEEE;
        }
    }
    .btn-active{
        background-color: transparent;
        border-color: #9e9e9e;
        color: #9e9e9e;
        cursor: default;
        &:hover,&:focus{
            background-color: transparent;
            border-color: #9e9e9e;
            color: #9e9e9e;
        }
    }
    .btn.order-delivery,.btn.order-billing{
        padding: 6px;
    }
    form .btn{
        margin: 15px 0;
    }
    .icon{
        -webkit-transition: .3s;
        transition: .3s;
        transition-property: initial;
        transition-duration: 0.3s;
        transition-timing-function: initial;
        transition-delay: initial;
        display: inline-block;
        position: relative;
        &:before{
            font-size: 20px;
        }
    }
    .flex-direction-nav a{
        line-height: 1;
        &:before{
            font-size: 40px !important;
            font-family: 'fontello' !important;
            display: inline-block !important;
            content: '\e806' !important;
            color: rgba(0, 0, 0, 0.5) !important;
        }
        &.flex-next:before {
            content: '\e807' !important;
            font-family: 'fontello' !important;
        }

    }
    .box-title {
        font-size: 20px;
        margin: 0 0 10px 0;
    }
    .capacity-string{
        margin-left: 10px;
    }
    .tiny-border {
        clear: both;
        width: 40px;
        height: 2px;
        background: $icon-color;
        margin: 0px 0 20px 0;
        &.light{
            background: rgba(255,255,255,0.4);
        }
    }
    .box-vilagos{
        overflow: hidden;
    }
/************************
	inputok + bejelentkezés old
// ************************/
    // input[type=text], input[type=email], input[type=password], textarea {
    //     border: 1px solid $border-color;
    //     border-radius: 3px;
    //     -webkit-border-radius: 3px;
    //     -moz-border-radius: 3px;
    //     padding: 10px;
    //     outline: none;
    //     width: 100%;
    //     margin-top: 5px;
    //     margin-bottom: 5px;
    //     font-size: 15px;
    //     font-family: sans-serif;
    // }
//     .text-div.error-text-div input,
//     .text-div.error-text-div textarea{
//     	background-color: #FDECF1;
//     }
//     .text-div.error-text-div input:hover,
//     .text-div.error-text-div textarea:hover{
//     	border-color: red;
//     }
//     .text-div-title {
//         margin: 15px 0 5px;
//         font-size: 16px;
//     }
//     .psw-reminder-submit{
//     	display: none;
//     }
//     .address-box{
//         border-bottom: 1px dotted #ddd;
//         overflow: hidden;
//     }
//     .new-address-btn{
//         margin: 30px 0px;
//         display: block;
//         clear: both;
//     }
//     .errors-global{
//         margin: 10px 0;
//         color: #F00;
//     }
//     .accept, .lost-password-button{
//         margin: 15px 0 0 0;
//         display: block;
//     }
//     .form-row{
//         margin: 10px 0;
//         .sub-row{
//             margin-left: 20px;
//         }
//     }
//     .address-input {
//         display: none; 
//     }
/**********************
	footer
**********************/


/**********************
	main-view
**********************/
    .main-left-container{
        float: none;
        width: 100%;
        padding: 5px;
        box-sizing: border-box;
        .main-left{
            width: 100%;
            box-sizing: border-box;
            height: auto;
        }
        #pic{
            display: block;
            line-height: 0;
            img{
                max-width: 100%;
            }
        }
        .hamarosan-lejaro-termek{
            float: none;
            display: block;
            margin: 8px auto;
            @include breakpoint(477px){
                margin: 8px 8px 0;
                display: inline-block;
            }
        }
    }
    .main-right-container{
        float: none;
        width: 100%;
        .main-right{
            width: 100%;
            height: auto;
            box-sizing: border-box;
        }
    }
    .main-right.shop-img-cont{
        box-sizing: content-box;
            width: 301px;
            height: 211px;
            margin-right: auto;
            margin-left: auto;

    }
    .main-news{
        overflow: inherit !important;
    }
    .box-top-vilagos36px, .box-top, .box-top-vilagos{
        overflow: hidden;
    }

/**********************
    kategoria-view
**********************/
    .right-container{
        width: 100%;

        .width730px,.width980px{
            width: 100%;
            box-sizing: border-box;
        }
    }
/*********************
    termék oldal
*********************/
    .share-div{
        span:first-of-type{
            display: none;
        }
    }
    .box{
        overflow: hidden;
        margin-bottom: 10px;
        >.padding-20{
            overflow: hidden;
        }
        .webterkep{
            margin-right: 68px;
        }
    }
    .aukcio-datas-blokk{
        overflow: hidden;
        height: unset;
        .prod-pic{
            width: 100%;
        }
        >table:first-of-type{
            width: 100%;
            td{
                width: 100%;
            }
            @include breakpoint(685px){
                width: unset;
            }
        }
        .aukcio-datas{
            margin-top: 20px;
            margin-left: 0;
            width: unset;
            @include breakpoint(685px){
               margin-top: 0px;
               margin-left: 20px;
            }
            @include breakpoint(760px){
               margin-top: 0px;
               margin-left: 80px;
            }
        }

    }
    .aukcio-leiras{
        clear: both;
        margin-top: 20px;
    }
/*********************
    kapcsolat oldal
*********************/

/*********************
    cart-views
*********************/
    .kosar_container{
        width: 100% !important;

    }
    .removed-from-cart{
        width: 100% !important;
    }
    .checkout-data-check{
        margin-top: 20px;
        margin-left: 0 !important;
    }
    .order-notice{
        width: 100% !important;
        max-width: 705px;
        box-sizing: border-box;
    }
    .order-comment{
        width: 100%;
        max-width: 666px;
        box-sizing: border-box;
    }
/*********************
    orders-page
*********************/

/*********************
    registration-page
*********************/
    .lock-pict{
        display: none;
    }
    .reg-sor{
        div:first-of-type{
            display: block;
        }
    }
    #regForm{
        form{
            margin-left: 0 !important;
        }
    }
/*********************
    grid
*********************/
    @include add-grid(6 at $mobile-tablet-middle);
    @include add-grid(12 at 900px);
    @include add-gutter(30px at $mobile-landscape);
    // @include add-gutter(0.3 at $mobile-tablet-middle);
    // @include add-gutter(20/82 at $desktop);
    // @include sgs-change('gutter styles', 'fixed');
    @include sgs-change('debug', true);
    @include sgs-change('include clearfix', true);
    @include add-gutter-style('split');
