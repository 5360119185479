/*******************
       color
*******************/
$background-color: #fff;
$darker-background-color: #efefef;
$header-background-color: #ffffff;
$footer-background-color: #363636;
$text-color-lighter: #666666;
$text-color-dark: #333333;
$text-color-light: #fff;

$text-color-middle: #9E9E9E;

$icon-color: #68a6dd;
// $icon-color: #00adef;
$border-color: #e8e6e6;

$link-color: #68a6dd;
$link-hover-color: #68a6dd;

/*******************
       sizes and typography
*******************/
$page-content-width: 1170px;
$page-min-width: 320px;
$default-font: 'Open Sans', Helvetica-Normal, Helvetica, Arial, sans-serif;
$title-font: sans-serif;

// for compass/typography
$base-font-size: 14px;
$base-line-height: 1.42857143;
$rhythm-unit: "px";


/********************
   breakpoints
********************/
$mobile-landscape: 480px;
$mobile-tablet-middle: 640px;
$nav-menu-show: 990px;
$desktop: 1000px;

/**
*Ezeket az értékeket felhasználhatjuk a javascriptben. A mintát látva tetszőlegesen hozzáírható
 **/
body:before {
  content: "mobile";
  display: none; /* Prevent from displaying. */
}
@media (min-width: $mobile-landscape) {
  body:before {
    content: "mobile-landscape";
  }
}
@media (min-width: $mobile-tablet-middle) {
  body:before {
    content: "mobile-tablet-middle";
  }
}
@media (min-width: $nav-menu-show) {
    body:before {
      content: "nav-menu-show";
  }
}
 @media (min-width: $desktop) {
    body:before {
      content: "desktop";
  }
}
