@charset "UTF-8";
/******************
       import
******************/
/*******************
       color
*******************/
/*******************
       sizes and typography
*******************/
/********************
   breakpoints
********************/
/**
*Ezeket az értékeket felhasználhatjuk a javascriptben. A mintát látva tetszőlegesen hozzáírható
 **/
body:before {
  content: "mobile";
  display: none;
  /* Prevent from displaying. */ }

@media (min-width: 480px) {
  body:before {
    content: "mobile-landscape"; } }

@media (min-width: 640px) {
  body:before {
    content: "mobile-tablet-middle"; } }

@media (min-width: 990px) {
  body:before {
    content: "nav-menu-show"; } }

@media (min-width: 1000px) {
  body:before {
    content: "desktop"; } }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("../fonts/glyphicons-halflings-regular.eot");
  src: url("../fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("../fonts/glyphicons-halflings-regular.woff") format("woff"), url("../fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg"); }

.glyphicon {
  position: relative;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.glyphicon-asterisk:before {
  content: "\002a"; }

.glyphicon-plus:before {
  content: "\002b"; }

.glyphicon-euro:before,
.glyphicon-eur:before {
  content: "\20ac"; }

.glyphicon-minus:before {
  content: "\2212"; }

.glyphicon-cloud:before {
  content: "\2601"; }

.glyphicon-envelope:before {
  content: "\2709"; }

.glyphicon-pencil:before {
  content: "\270f"; }

.glyphicon-glass:before {
  content: "\e001"; }

.glyphicon-music:before {
  content: "\e002"; }

.glyphicon-search:before {
  content: "\e003"; }

.glyphicon-heart:before {
  content: "\e005"; }

.glyphicon-star:before {
  content: "\e006"; }

.glyphicon-star-empty:before {
  content: "\e007"; }

.glyphicon-user:before {
  content: "\e008"; }

.glyphicon-film:before {
  content: "\e009"; }

.glyphicon-th-large:before {
  content: "\e010"; }

.glyphicon-th:before {
  content: "\e011"; }

.glyphicon-th-list:before {
  content: "\e012"; }

.glyphicon-ok:before {
  content: "\e013"; }

.glyphicon-remove:before {
  content: "\e014"; }

.glyphicon-zoom-in:before {
  content: "\e015"; }

.glyphicon-zoom-out:before {
  content: "\e016"; }

.glyphicon-off:before {
  content: "\e017"; }

.glyphicon-signal:before {
  content: "\e018"; }

.glyphicon-cog:before {
  content: "\e019"; }

.glyphicon-trash:before {
  content: "\e020"; }

.glyphicon-home:before {
  content: "\e021"; }

.glyphicon-file:before {
  content: "\e022"; }

.glyphicon-time:before {
  content: "\e023"; }

.glyphicon-road:before {
  content: "\e024"; }

.glyphicon-download-alt:before {
  content: "\e025"; }

.glyphicon-download:before {
  content: "\e026"; }

.glyphicon-upload:before {
  content: "\e027"; }

.glyphicon-inbox:before {
  content: "\e028"; }

.glyphicon-play-circle:before {
  content: "\e029"; }

.glyphicon-repeat:before {
  content: "\e030"; }

.glyphicon-refresh:before {
  content: "\e031"; }

.glyphicon-list-alt:before {
  content: "\e032"; }

.glyphicon-lock:before {
  content: "\e033"; }

.glyphicon-flag:before {
  content: "\e034"; }

.glyphicon-headphones:before {
  content: "\e035"; }

.glyphicon-volume-off:before {
  content: "\e036"; }

.glyphicon-volume-down:before {
  content: "\e037"; }

.glyphicon-volume-up:before {
  content: "\e038"; }

.glyphicon-qrcode:before {
  content: "\e039"; }

.glyphicon-barcode:before {
  content: "\e040"; }

.glyphicon-tag:before {
  content: "\e041"; }

.glyphicon-tags:before {
  content: "\e042"; }

.glyphicon-book:before {
  content: "\e043"; }

.glyphicon-bookmark:before {
  content: "\e044"; }

.glyphicon-print:before {
  content: "\e045"; }

.glyphicon-camera:before {
  content: "\e046"; }

.glyphicon-font:before {
  content: "\e047"; }

.glyphicon-bold:before {
  content: "\e048"; }

.glyphicon-italic:before {
  content: "\e049"; }

.glyphicon-text-height:before {
  content: "\e050"; }

.glyphicon-text-width:before {
  content: "\e051"; }

.glyphicon-align-left:before {
  content: "\e052"; }

.glyphicon-align-center:before {
  content: "\e053"; }

.glyphicon-align-right:before {
  content: "\e054"; }

.glyphicon-align-justify:before {
  content: "\e055"; }

.glyphicon-list:before {
  content: "\e056"; }

.glyphicon-indent-left:before {
  content: "\e057"; }

.glyphicon-indent-right:before {
  content: "\e058"; }

.glyphicon-facetime-video:before {
  content: "\e059"; }

.glyphicon-picture:before {
  content: "\e060"; }

.glyphicon-map-marker:before {
  content: "\e062"; }

.glyphicon-adjust:before {
  content: "\e063"; }

.glyphicon-tint:before {
  content: "\e064"; }

.glyphicon-edit:before {
  content: "\e065"; }

.glyphicon-share:before {
  content: "\e066"; }

.glyphicon-check:before {
  content: "\e067"; }

.glyphicon-move:before {
  content: "\e068"; }

.glyphicon-step-backward:before {
  content: "\e069"; }

.glyphicon-fast-backward:before {
  content: "\e070"; }

.glyphicon-backward:before {
  content: "\e071"; }

.glyphicon-play:before {
  content: "\e072"; }

.glyphicon-pause:before {
  content: "\e073"; }

.glyphicon-stop:before {
  content: "\e074"; }

.glyphicon-forward:before {
  content: "\e075"; }

.glyphicon-fast-forward:before {
  content: "\e076"; }

.glyphicon-step-forward:before {
  content: "\e077"; }

.glyphicon-eject:before {
  content: "\e078"; }

.glyphicon-chevron-left:before {
  content: "\e079"; }

.glyphicon-chevron-right:before {
  content: "\e080"; }

.glyphicon-plus-sign:before {
  content: "\e081"; }

.glyphicon-minus-sign:before {
  content: "\e082"; }

.glyphicon-remove-sign:before {
  content: "\e083"; }

.glyphicon-ok-sign:before {
  content: "\e084"; }

.glyphicon-question-sign:before {
  content: "\e085"; }

.glyphicon-info-sign:before {
  content: "\e086"; }

.glyphicon-screenshot:before {
  content: "\e087"; }

.glyphicon-remove-circle:before {
  content: "\e088"; }

.glyphicon-ok-circle:before {
  content: "\e089"; }

.glyphicon-ban-circle:before {
  content: "\e090"; }

.glyphicon-arrow-left:before {
  content: "\e091"; }

.glyphicon-arrow-right:before {
  content: "\e092"; }

.glyphicon-arrow-up:before {
  content: "\e093"; }

.glyphicon-arrow-down:before {
  content: "\e094"; }

.glyphicon-share-alt:before {
  content: "\e095"; }

.glyphicon-resize-full:before {
  content: "\e096"; }

.glyphicon-resize-small:before {
  content: "\e097"; }

.glyphicon-exclamation-sign:before {
  content: "\e101"; }

.glyphicon-gift:before {
  content: "\e102"; }

.glyphicon-leaf:before {
  content: "\e103"; }

.glyphicon-fire:before {
  content: "\e104"; }

.glyphicon-eye-open:before {
  content: "\e105"; }

.glyphicon-eye-close:before {
  content: "\e106"; }

.glyphicon-warning-sign:before {
  content: "\e107"; }

.glyphicon-plane:before {
  content: "\e108"; }

.glyphicon-calendar:before {
  content: "\e109"; }

.glyphicon-random:before {
  content: "\e110"; }

.glyphicon-comment:before {
  content: "\e111"; }

.glyphicon-magnet:before {
  content: "\e112"; }

.glyphicon-chevron-up:before {
  content: "\e113"; }

.glyphicon-chevron-down:before {
  content: "\e114"; }

.glyphicon-retweet:before {
  content: "\e115"; }

.glyphicon-shopping-cart:before {
  content: "\e116"; }

.glyphicon-folder-close:before {
  content: "\e117"; }

.glyphicon-folder-open:before {
  content: "\e118"; }

.glyphicon-resize-vertical:before {
  content: "\e119"; }

.glyphicon-resize-horizontal:before {
  content: "\e120"; }

.glyphicon-hdd:before {
  content: "\e121"; }

.glyphicon-bullhorn:before {
  content: "\e122"; }

.glyphicon-bell:before {
  content: "\e123"; }

.glyphicon-certificate:before {
  content: "\e124"; }

.glyphicon-thumbs-up:before {
  content: "\e125"; }

.glyphicon-thumbs-down:before {
  content: "\e126"; }

.glyphicon-hand-right:before {
  content: "\e127"; }

.glyphicon-hand-left:before {
  content: "\e128"; }

.glyphicon-hand-up:before {
  content: "\e129"; }

.glyphicon-hand-down:before {
  content: "\e130"; }

.glyphicon-circle-arrow-right:before {
  content: "\e131"; }

.glyphicon-circle-arrow-left:before {
  content: "\e132"; }

.glyphicon-circle-arrow-up:before {
  content: "\e133"; }

.glyphicon-circle-arrow-down:before {
  content: "\e134"; }

.glyphicon-globe:before {
  content: "\e135"; }

.glyphicon-wrench:before {
  content: "\e136"; }

.glyphicon-tasks:before {
  content: "\e137"; }

.glyphicon-filter:before {
  content: "\e138"; }

.glyphicon-briefcase:before {
  content: "\e139"; }

.glyphicon-fullscreen:before {
  content: "\e140"; }

.glyphicon-dashboard:before {
  content: "\e141"; }

.glyphicon-paperclip:before {
  content: "\e142"; }

.glyphicon-heart-empty:before {
  content: "\e143"; }

.glyphicon-link:before {
  content: "\e144"; }

.glyphicon-phone:before {
  content: "\e145"; }

.glyphicon-pushpin:before {
  content: "\e146"; }

.glyphicon-usd:before {
  content: "\e148"; }

.glyphicon-gbp:before {
  content: "\e149"; }

.glyphicon-sort:before {
  content: "\e150"; }

.glyphicon-sort-by-alphabet:before {
  content: "\e151"; }

.glyphicon-sort-by-alphabet-alt:before {
  content: "\e152"; }

.glyphicon-sort-by-order:before {
  content: "\e153"; }

.glyphicon-sort-by-order-alt:before {
  content: "\e154"; }

.glyphicon-sort-by-attributes:before {
  content: "\e155"; }

.glyphicon-sort-by-attributes-alt:before {
  content: "\e156"; }

.glyphicon-unchecked:before {
  content: "\e157"; }

.glyphicon-expand:before {
  content: "\e158"; }

.glyphicon-collapse-down:before {
  content: "\e159"; }

.glyphicon-collapse-up:before {
  content: "\e160"; }

.glyphicon-log-in:before {
  content: "\e161"; }

.glyphicon-flash:before {
  content: "\e162"; }

.glyphicon-log-out:before {
  content: "\e163"; }

.glyphicon-new-window:before {
  content: "\e164"; }

.glyphicon-record:before {
  content: "\e165"; }

.glyphicon-save:before {
  content: "\e166"; }

.glyphicon-open:before {
  content: "\e167"; }

.glyphicon-saved:before {
  content: "\e168"; }

.glyphicon-import:before {
  content: "\e169"; }

.glyphicon-export:before {
  content: "\e170"; }

.glyphicon-send:before {
  content: "\e171"; }

.glyphicon-floppy-disk:before {
  content: "\e172"; }

.glyphicon-floppy-saved:before {
  content: "\e173"; }

.glyphicon-floppy-remove:before {
  content: "\e174"; }

.glyphicon-floppy-save:before {
  content: "\e175"; }

.glyphicon-floppy-open:before {
  content: "\e176"; }

.glyphicon-credit-card:before {
  content: "\e177"; }

.glyphicon-transfer:before {
  content: "\e178"; }

.glyphicon-cutlery:before {
  content: "\e179"; }

.glyphicon-header:before {
  content: "\e180"; }

.glyphicon-compressed:before {
  content: "\e181"; }

.glyphicon-earphone:before {
  content: "\e182"; }

.glyphicon-phone-alt:before {
  content: "\e183"; }

.glyphicon-tower:before {
  content: "\e184"; }

.glyphicon-stats:before {
  content: "\e185"; }

.glyphicon-sd-video:before {
  content: "\e186"; }

.glyphicon-hd-video:before {
  content: "\e187"; }

.glyphicon-subtitles:before {
  content: "\e188"; }

.glyphicon-sound-stereo:before {
  content: "\e189"; }

.glyphicon-sound-dolby:before {
  content: "\e190"; }

.glyphicon-sound-5-1:before {
  content: "\e191"; }

.glyphicon-sound-6-1:before {
  content: "\e192"; }

.glyphicon-sound-7-1:before {
  content: "\e193"; }

.glyphicon-copyright-mark:before {
  content: "\e194"; }

.glyphicon-registration-mark:before {
  content: "\e195"; }

.glyphicon-cloud-download:before {
  content: "\e197"; }

.glyphicon-cloud-upload:before {
  content: "\e198"; }

.glyphicon-tree-conifer:before {
  content: "\e199"; }

.glyphicon-tree-deciduous:before {
  content: "\e200"; }

.glyphicon-cd:before {
  content: "\e201"; }

.glyphicon-save-file:before {
  content: "\e202"; }

.glyphicon-open-file:before {
  content: "\e203"; }

.glyphicon-level-up:before {
  content: "\e204"; }

.glyphicon-copy:before {
  content: "\e205"; }

.glyphicon-paste:before {
  content: "\e206"; }

.glyphicon-alert:before {
  content: "\e209"; }

.glyphicon-equalizer:before {
  content: "\e210"; }

.glyphicon-king:before {
  content: "\e211"; }

.glyphicon-queen:before {
  content: "\e212"; }

.glyphicon-pawn:before {
  content: "\e213"; }

.glyphicon-bishop:before {
  content: "\e214"; }

.glyphicon-knight:before {
  content: "\e215"; }

.glyphicon-baby-formula:before {
  content: "\e216"; }

.glyphicon-tent:before {
  content: "\26fa"; }

.glyphicon-blackboard:before {
  content: "\e218"; }

.glyphicon-bed:before {
  content: "\e219"; }

.glyphicon-apple:before {
  content: "\f8ff"; }

.glyphicon-erase:before {
  content: "\e221"; }

.glyphicon-hourglass:before {
  content: "\231b"; }

.glyphicon-lamp:before {
  content: "\e223"; }

.glyphicon-duplicate:before {
  content: "\e224"; }

.glyphicon-piggy-bank:before {
  content: "\e225"; }

.glyphicon-scissors:before {
  content: "\e226"; }

.glyphicon-bitcoin:before {
  content: "\e227"; }

.glyphicon-btc:before {
  content: "\e227"; }

.glyphicon-xbt:before {
  content: "\e227"; }

.glyphicon-yen:before {
  content: "\00a5"; }

.glyphicon-jpy:before {
  content: "\00a5"; }

.glyphicon-ruble:before {
  content: "\20bd"; }

.glyphicon-rub:before {
  content: "\20bd"; }

.glyphicon-scale:before {
  content: "\e230"; }

.glyphicon-ice-lolly:before {
  content: "\e231"; }

.glyphicon-ice-lolly-tasted:before {
  content: "\e232"; }

.glyphicon-education:before {
  content: "\e233"; }

.glyphicon-option-horizontal:before {
  content: "\e234"; }

.glyphicon-option-vertical:before {
  content: "\e235"; }

.glyphicon-menu-hamburger:before {
  content: "\e236"; }

.glyphicon-modal-window:before {
  content: "\e237"; }

.glyphicon-oil:before {
  content: "\e238"; }

.glyphicon-grain:before {
  content: "\e239"; }

.glyphicon-sunglasses:before {
  content: "\e240"; }

.glyphicon-text-size:before {
  content: "\e241"; }

.glyphicon-text-color:before {
  content: "\e242"; }

.glyphicon-text-background:before {
  content: "\e243"; }

.glyphicon-object-align-top:before {
  content: "\e244"; }

.glyphicon-object-align-bottom:before {
  content: "\e245"; }

.glyphicon-object-align-horizontal:before {
  content: "\e246"; }

.glyphicon-object-align-left:before {
  content: "\e247"; }

.glyphicon-object-align-vertical:before {
  content: "\e248"; }

.glyphicon-object-align-right:before {
  content: "\e249"; }

.glyphicon-triangle-right:before {
  content: "\e250"; }

.glyphicon-triangle-left:before {
  content: "\e251"; }

.glyphicon-triangle-bottom:before {
  content: "\e252"; }

.glyphicon-triangle-top:before {
  content: "\e253"; }

.glyphicon-console:before {
  content: "\e254"; }

.glyphicon-superscript:before {
  content: "\e255"; }

.glyphicon-subscript:before {
  content: "\e256"; }

.glyphicon-menu-left:before {
  content: "\e257"; }

.glyphicon-menu-right:before {
  content: "\e258"; }

.glyphicon-menu-down:before {
  content: "\e259"; }

.glyphicon-menu-up:before {
  content: "\e260"; }

/************************
    köret
************************/
@media (min-width: 990px) {
  #menu-button {
    display: none; } }

.nagydiv {
  width: 100%;
  min-width: 320px;
  padding: 5px 5px;
  box-sizing: border-box; }

.fejlec {
  height: 82px;
  width: 100%;
  position: relative;
  z-index: 2; }
  @media (max-width: 355px) {
    .fejlec > a:first-child img {
      width: 80px;
      margin-top: 9px; } }
  .fejlec .header-welcome {
    display: none; }
  .fejlec .personal-menu {
    float: right;
    margin-top: 52px;
    margin-left: 5px; }
    .fejlec .personal-menu button {
      margin: 0; }
    @media (max-width: 575px) {
      .fejlec .personal-menu {
        display: block; } }

.mobil-menu-holder {
  clear: both;
  -webkit-user-select: none;
  display: block;
  margin: -2px 0 0;
  overflow-y: hidden;
  padding: 0;
  position: relative;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.navbar {
  position: relative;
  height: 44px;
  overflow: hidden;
  width: 100%; }
  .navbar .slide-menu-fade {
    background: linear-gradient(linear, left top, right top, color-stop(0, rgba(222, 216, 200, 0)), color-stop(100%, #ded8c8));
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ded8c8+0,ded8c8+38&0+0,1+38 */
    background: -moz-linear-gradient(left, rgba(222, 216, 200, 0) 0%, #ded8c8 38%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(222, 216, 200, 0) 0%, #ded8c8 38%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(222, 216, 200, 0) 0%, #ded8c8 38%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ded8c8', endColorstr='#ded8c8',GradientType=1 );
    /* IE6-9 */
    height: 100%;
    position: absolute;
    pointer-events: none;
    right: -2px;
    top: 0;
    width: 20px;
    z-index: 1; }

.menu-container {
  padding-right: 14px; }

.mobil-menu {
  width: 100%;
  clear: both; }
  .mobil-menu .menu, .mobil-menu .menu:visited, .mobil-menu .menu:link {
    text-transform: uppercase;
    color: #fff;
    float: none;
    margin-right: 10px; }

.page {
  width: 100%; }

.footer-container {
  width: 100%; }

@media (min-width: 0) and (max-width: 575px) {
  .login-container {
    display: none;
    position: absolute;
    z-index: 4;
    top: 94px;
    background: #f5f5f5;
    width: 100%;
    padding: 10px 5px 10px;
    box-sizing: border-box;
    margin: 0 0 0; } }

@media (min-width: 576px) {
  .login-container {
    display: block; } }

.mobile-min430px {
  min-width: 430px; }

.rotate-warning {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: red;
  text-align: center; }
  @media (min-width: 440px) {
    .rotate-warning {
      display: none; } }

.left-container {
  margin-top: 10px; }

/************************
    aukciók
************************/
.left-almenu {
  position: relative !important;
  left: unset !important;
  top: unset !important;
  display: block !important;
  border: none !important; }

.more {
  background: none !important; }

@media (max-width: 580px) {
  .table-responsive * {
    box-sizing: border-box; }
  .table-responsive br {
    content: ' '; }
  .table-responsive br:after {
    content: ' '; }
  .table-responsive thead {
    display: none; }
  .table-responsive tr {
    display: block;
    overflow: hidden; }
    .table-responsive tr td:nth-of-type(1) {
      display: block;
      float: left;
      width: 90px; }
    .table-responsive tr td:nth-of-type(2) {
      display: block;
      float: left;
      width: calc(100% - 90px); }
      .table-responsive tr td:nth-of-type(2) > * {
        margin-left: 10px; }
    .table-responsive tr td:nth-of-type(3), .table-responsive tr td:nth-of-type(4), .table-responsive tr td:nth-of-type(5) {
      display: block;
      float: left; }
      .table-responsive tr td:nth-of-type(3):before, .table-responsive tr td:nth-of-type(4):before, .table-responsive tr td:nth-of-type(5):before {
        content: attr(data-th);
        float: left;
        text-align: left;
        padding-right: 10px; }
    .table-responsive tr td:nth-of-type(3) {
      width: 40%;
      text-align: left; }
    .table-responsive tr td:nth-of-type(4) {
      width: 60%;
      text-align: right; }
    .table-responsive tr td:nth-of-type(5) {
      text-align: right;
      float: right;
      font-weight: bold; }
    .table-responsive tr .termek-nev {
      margin-top: 0; }
    .table-responsive tr .lista-kosarba-gomb {
      width: 132px; }
    .table-responsive tr .empty-category {
      width: 100% !important; }
  .table-responsive.webshop td:nth-of-type(3) {
    width: 100%;
    text-align: left; }
  .table-responsive.webshop td:nth-of-type(4) {
    text-align: left; }
  .table-responsive.webshop td:nth-of-type(6) {
    float: right;
    padding: 5px 5px 10px; } }

/************************
	design elemek
************************/
.btn, a.btn {
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-transition: .3s;
  transition: .3s;
  padding: 10px;
  display: inline-block;
  text-decoration: none;
  border: none;
  font-size: 14px;
  font-family: "Open Sans", Helvetica-Normal, Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-align: center;
  background-color: #68a6dd;
  color: #fff; }
  .btn:hover, .btn:focus, a.btn:hover, a.btn:focus {
    background-color: #2C3E50;
    color: #fff; }
  .btn:active, a.btn:active {
    transform: translate(1px, 1px); }

.btn-green, a.btn-green {
  background-color: #a3bd35; }
  .btn-green:hover, .btn-green:focus, a.btn-green:hover, a.btn-green:focus {
    background-color: #59671f; }

.btn-transparent, a.btn-transparent {
  background: transparent;
  border: solid 2px #68a6dd;
  color: #68a6dd;
  padding: 8px; }
  .btn-transparent:hover, a.btn-transparent:hover {
    border-color: #2C3E50; }

.btn-inactive, a.btn-inactive {
  background-color: #EEEEEE;
  color: white;
  border: #EEEEEE;
  cursor: default; }
  .btn-inactive:hover, .btn-inactive:focus, a.btn-inactive:hover, a.btn-inactive:focus {
    background-color: #EEEEEE;
    color: white;
    border: #EEEEEE; }

.btn-active {
  background-color: transparent;
  border-color: #9e9e9e;
  color: #9e9e9e;
  cursor: default; }
  .btn-active:hover, .btn-active:focus {
    background-color: transparent;
    border-color: #9e9e9e;
    color: #9e9e9e; }

.btn.order-delivery, .btn.order-billing {
  padding: 6px; }

form .btn {
  margin: 15px 0; }

.icon {
  -webkit-transition: .3s;
  transition: .3s;
  transition-property: initial;
  transition-duration: 0.3s;
  transition-timing-function: initial;
  transition-delay: initial;
  display: inline-block;
  position: relative; }
  .icon:before {
    font-size: 20px; }

.flex-direction-nav a {
  line-height: 1; }
  .flex-direction-nav a:before {
    font-size: 40px !important;
    font-family: 'fontello' !important;
    display: inline-block !important;
    content: '\e806' !important;
    color: rgba(0, 0, 0, 0.5) !important; }
  .flex-direction-nav a.flex-next:before {
    content: '\e807' !important;
    font-family: 'fontello' !important; }

.box-title {
  font-size: 20px;
  margin: 0 0 10px 0; }

.capacity-string {
  margin-left: 10px; }

.tiny-border {
  clear: both;
  width: 40px;
  height: 2px;
  background: #68a6dd;
  margin: 0px 0 20px 0; }
  .tiny-border.light {
    background: rgba(255, 255, 255, 0.4); }

.box-vilagos {
  overflow: hidden; }

/************************
	inputok + bejelentkezés old
// ************************/
/**********************
	footer
**********************/
/**********************
	main-view
**********************/
.main-left-container {
  float: none;
  width: 100%;
  padding: 5px;
  box-sizing: border-box; }
  .main-left-container .main-left {
    width: 100%;
    box-sizing: border-box;
    height: auto; }
  .main-left-container #pic {
    display: block;
    line-height: 0; }
    .main-left-container #pic img {
      max-width: 100%; }
  .main-left-container .hamarosan-lejaro-termek {
    float: none;
    display: block;
    margin: 8px auto; }
    @media (min-width: 477px) {
      .main-left-container .hamarosan-lejaro-termek {
        margin: 8px 8px 0;
        display: inline-block; } }

.main-right-container {
  float: none;
  width: 100%; }
  .main-right-container .main-right {
    width: 100%;
    height: auto;
    box-sizing: border-box; }

.main-right.shop-img-cont {
  box-sizing: content-box;
  width: 301px;
  height: 211px;
  margin-right: auto;
  margin-left: auto; }

.main-news {
  overflow: inherit !important; }

.box-top-vilagos36px, .box-top, .box-top-vilagos {
  overflow: hidden; }

/**********************
    kategoria-view
**********************/
.right-container {
  width: 100%; }
  .right-container .width730px, .right-container .width980px {
    width: 100%;
    box-sizing: border-box; }

/*********************
    termék oldal
*********************/
.share-div span:first-of-type {
  display: none; }

.box {
  overflow: hidden;
  margin-bottom: 10px; }
  .box > .padding-20 {
    overflow: hidden; }
  .box .webterkep {
    margin-right: 68px; }

.aukcio-datas-blokk {
  overflow: hidden;
  height: unset; }
  .aukcio-datas-blokk .prod-pic {
    width: 100%; }
  .aukcio-datas-blokk > table:first-of-type {
    width: 100%; }
    .aukcio-datas-blokk > table:first-of-type td {
      width: 100%; }
    @media (min-width: 685px) {
      .aukcio-datas-blokk > table:first-of-type {
        width: unset; } }
  .aukcio-datas-blokk .aukcio-datas {
    margin-top: 20px;
    margin-left: 0;
    width: unset; }
    @media (min-width: 685px) {
      .aukcio-datas-blokk .aukcio-datas {
        margin-top: 0px;
        margin-left: 20px; } }
    @media (min-width: 760px) {
      .aukcio-datas-blokk .aukcio-datas {
        margin-top: 0px;
        margin-left: 80px; } }

.aukcio-leiras {
  clear: both;
  margin-top: 20px; }

/*********************
    kapcsolat oldal
*********************/
/*********************
    cart-views
*********************/
.kosar_container {
  width: 100% !important; }

.removed-from-cart {
  width: 100% !important; }

.checkout-data-check {
  margin-top: 20px;
  margin-left: 0 !important; }

.order-notice {
  width: 100% !important;
  max-width: 705px;
  box-sizing: border-box; }

.order-comment {
  width: 100%;
  max-width: 666px;
  box-sizing: border-box; }

/*********************
    orders-page
*********************/
/*********************
    registration-page
*********************/
.lock-pict {
  display: none; }

.reg-sor div:first-of-type {
  display: block; }

#regForm form {
  margin-left: 0 !important; }

/*********************
    grid
*********************/
